import React from 'react';
import Layout from '../components/Layout';
import withTranslations from '../components/hoc/withTranslations';
import Together from '../components/Together';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import Seo from '../components/Seo';
import { useTranslation } from 'react-i18next';

const technologies = [
  'GraphQL',
  'AWS',
  'React',
  'Kubernetes',
  'NodeJS',
  'Spring',
  'Flutter',
  'Docker',
  'NextJS',
  'MongoDB',
  'Django',
  'Terraform',
  'Oracle',
  'Unity',
  'GatsbyJS'
];

const technologiesMap = {
  GraphQL: '#e20098',
  AWS: '#ff9900',
  React: '#61dafb',
  Kubernetes: '#316ce6',
  NodeJS: '#6da75d',
  Spring: '#5fb832',
  Flutter: '#54c5f8',
  Docker: '#2496ed',
  NextJS: '#999999',
  MongoDB: '#449a44',
  Django: 'rgb(35, 122, 89)',
  Terraform: '#5c4ee5',
  Oracle: '#ee1014',
  Unity: '#5c5151',
  GatsbyJS: '#744c9f'
};

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

const Technologies = props => {
  const { t } = useTranslation();

  let data = useStaticQuery(graphql`
    query {
      graphQL: file(relativePath: { eq: "technologies/graphql.png" }) {
        ...technologyLogo
      }

      aws: file(relativePath: { eq: "technologies/aws.png" }) {
        ...technologyLogo
      }

      react: file(relativePath: { eq: "technologies/react.png" }) {
        ...technologyLogo
      }

      kubernetes: file(relativePath: { eq: "technologies/kubernetes.png" }) {
        ...technologyLogo
      }

      node: file(relativePath: { eq: "technologies/node.png" }) {
        ...technologyLogo
      }

      spring: file(relativePath: { eq: "technologies/spring.png" }) {
        ...technologyLogo
      }

      flutter: file(relativePath: { eq: "technologies/flutter.png" }) {
        ...technologyLogo
      }

      docker1: file(relativePath: { eq: "technologies/docker.png" }) {
        ...technologyLogo
      }

      next: file(relativePath: { eq: "technologies/nextjs.png" }) {
        ...technologyLogo
      }

      mongo: file(relativePath: { eq: "technologies/mongo.png" }) {
        ...technologyLogo
      }

      django: file(relativePath: { eq: "technologies/django.png" }) {
        ...technologyLogo
      }

      terraform: file(relativePath: { eq: "technologies/terraform.png" }) {
        ...technologyLogo
      }

      oracle: file(relativePath: { eq: "technologies/oracle.png" }) {
        ...technologyLogo
      }

      unity: file(relativePath: { eq: "technologies/unity.png" }) {
        ...technologyLogo
      }

      gatsby: file(relativePath: { eq: "technologies/gatsby.png" }) {
        ...technologyLogo
      }
    }
  `);

  const imgHeight = '120px';
  const imgWidth = '120px';
  const imgMargin = '40px';

  return (
    <>
      <Layout manualMargin manualPadding>
        <Seo
          title={t('technologies.seoTitle')}
          description={t('technologies.seoDescription')}
          keywords={['tecnologia moderna', 'vanguardia', 'tecnologías', 'tech']}
        />
        <div className='animation global-margin global-padding global-section'>
          <span className='we'>{t('technologies.weUse')}</span>
          <div className='words'>
            {shuffle(technologies).map(technology => (
              <span style={{ color: technologiesMap[technology] }}>{technology}</span>
            ))}
          </div>
        </div>
        <section className='techs global-light global-section'>
          <div className=' global-margin global-padding content'>
            <h2>{t('technologies.title')}</h2>
            <div className='images'>
              <Img
                style={{ height: imgHeight, width: imgWidth, margin: imgMargin }}
                fluid={data.graphQL.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
                alt='GraphQL'
              />

              <Img
                style={{ height: imgHeight, width: imgWidth, margin: imgMargin }}
                fluid={data.aws.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
                alt='aws'
              />

              <Img
                style={{ height: imgHeight, width: imgWidth, margin: imgMargin }}
                fluid={data.react.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
                alt='react'
              />

              <Img
                style={{ height: imgHeight, width: imgWidth, margin: imgMargin }}
                fluid={data.kubernetes.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
                alt='kubernetes'
              />

              <Img
                style={{ height: imgHeight, width: imgWidth, margin: imgMargin }}
                fluid={data.node.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
                alt='node'
              />

              <Img
                style={{ height: imgHeight, width: imgWidth, margin: imgMargin }}
                fluid={data.spring.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
                alt='spring'
              />

              <Img
                style={{ height: imgHeight, width: imgWidth, margin: imgMargin }}
                fluid={data.flutter.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
                alt='flutter'
              />

              <Img
                style={{ height: imgHeight, width: imgWidth, margin: imgMargin }}
                fluid={data.docker1.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
                alt='docker'
              />

              <Img
                style={{ height: imgHeight, width: imgWidth, margin: imgMargin }}
                fluid={data.next.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
                alt='next'
              />

              <Img
                style={{ height: imgHeight, width: imgWidth, margin: imgMargin }}
                fluid={data.mongo.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
                alt='mongo'
              />

              <Img
                style={{ height: imgHeight, width: imgWidth, margin: imgMargin }}
                fluid={data.django.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
                alt='django'
              />

              <Img
                style={{ height: imgHeight, width: imgWidth, margin: imgMargin }}
                fluid={data.terraform.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
                alt='terraform'
              />

              <Img
                style={{ height: imgHeight, width: imgWidth, margin: imgMargin }}
                fluid={data.oracle.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
                alt='oracle'
              />

              <Img
                style={{ height: imgHeight, width: imgWidth, margin: imgMargin }}
                fluid={data.unity.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
                alt='unity'
              />

              <Img
                style={{ height: imgHeight, width: imgWidth, margin: imgMargin }}
                fluid={data.gatsby.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
                alt='gatsby'
              />
            </div>
          </div>
        </section>
        <Together />
      </Layout>

      <style jsx>{`
        .techs {
          background-color: var(--background-white);
          clip-path: polygon(0 80px, 100% 0, 100% 100%, 0% 100%);
        }

        .content {
          text-align: center;
          justify-content: center;
          padding-top: 100px;
          padding-bottom: 100px;
        }

        .images {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }

        h1 {
          color: black;
        }

        .animation {
          display: flex;
          flex-wrap: wrap;
        }

        .we {
          font-size: 8vmax;
          color: white;
          font-family: Poppins;
          font-weight: bold;
          margin-right: 20px;
        }

        .words {
          font-size: 8vmax;
          color: #ffa500;
          font-family: Poppins;
          font-weight: bold;
          width: 85vw;
          max-width: 425px;
          overflow: hidden;
          text-align: left;
        }

        @keyframes rotateWordsFirst {
          0% {
            opacity: 0;
            animation-timing-function: ease-in;
            width: 0px;
          }
          2% {
            opacity: 0.3;
            width: 0px;
          }
          4% {
            opacity: 1;
            width: 55%;
          }
          6% {
            opacity: 0;
            width: 55%;
          }
          100% {
            opacity: 0;
          }
        }

        .words span {
          position: absolute;
          opacity: 0;
          overflow: hidden;
          height: 11vmax;
          color: #6b969d;
        }

        .words span {
          animation-name: rotateWordsFirst;
          animation-duration: 45s;
          animation-timing-function: linear;
          animation-delay: 0s;
          animation-iteration-count: infinite;
        }

        .words span:nth-child(1) {
          animation-delay: 0s;
        }

        .words span:nth-child(2) {
          animation-delay: 3s;
        }
        .words span:nth-child(3) {
          animation-delay: 6s;
        }
        .words span:nth-child(4) {
          animation-delay: 9s;
        }
        .words span:nth-child(5) {
          animation-delay: 12s;
        }
        .words span:nth-child(6) {
          animation-delay: 15s;
        }
        .words span:nth-child(7) {
          animation-delay: 18s;
        }
        .words span:nth-child(8) {
          animation-delay: 21s;
        }
        .words span:nth-child(9) {
          animation-delay: 24s;
        }
        .words span:nth-child(10) {
          animation-delay: 27s;
        }
        .words span:nth-child(11) {
          animation-delay: 30s;
        }
        .words span:nth-child(12) {
          animation-delay: 33s;
        }
        .words span:nth-child(13) {
          animation-delay: 36s;
        }
        .words span:nth-child(14) {
          animation-delay: 39s;
        }
        .words span:nth-child(15) {
          animation-delay: 42s;
        }

        @media only screen and (max-width: 600px) {
          h1 {
            font-size: 45px;
          }

          .words {
            font-size: 6vmax;
          }

          .words span {
            height: 9vmax;
          }

          .animation {
            flex-direction: column;
          }

          .we {
            font-size: 6vmax;
          }

          @keyframes rotateWordsFirst {
            0% {
              opacity: 0;
              animation-timing-function: ease-in;
              width: 0px;
            }
            2% {
              opacity: 0.3;
              width: 0px;
            }
            4% {
              opacity: 1;
              width: 80%;
            }
            6% {
              opacity: 0;
              width: 80%;
            }
            100% {
              opacity: 0;
            }
          }
        }
      `}</style>
    </>
  );
};

export default withTranslations()(Technologies);
